import {
  AssetDeviceGatewayModel,
  AssetDeviceGatewayPayload,
  AssetDeviceModel,
  AssetDevicePayload,
  AssetDeviceSensorModel,
  AssetDeviceSensorPayload,
  AssetDevicesModel,
  AssetDevicesPayload,
} from '../models';

const payloadToGatewayDeviceSensor = ({
  access_sensor_id,
  device_id,
  item_name,
  item_type,
  sensor_id,
  sensor_uuid,
  sensor_type,
  name,
  room,
  usage,
}: AssetDeviceSensorPayload): AssetDeviceSensorModel => {
  return {
    accessSensorId: access_sensor_id ?? '',
    deviceId: device_id ?? '',
    itemName: item_name ?? '',
    itemType: item_type ?? '',
    sensorId: sensor_id ?? '',
    sensorUuid: sensor_uuid ?? '',
    sensorType: sensor_type ?? '',
    name: name ?? null,
    room: room ?? null,
    usage: usage ?? null,
  };
};

const payloadToGatewayDevice = ({
  available_actions,
  battery_level,
  connectivity,
  device_id,
  device_name,
  device_type,
  enabled,
  lock_type,
  parameters,
  password,
  sensors,
  thing_id,
  thing_type_uid,
  uuid,
}: AssetDevicePayload): AssetDeviceModel => {
  const transformedSensors = sensors.map((sensor) => payloadToGatewayDeviceSensor(sensor)) ?? [];

  return {
    availableActions: available_actions ?? [],
    batteryLevel: battery_level ?? null,
    connectivity: connectivity ?? null,
    deviceId: device_id ?? '',
    deviceName: device_name ?? '',
    deviceType: device_type ?? '',
    enabled: enabled ?? false,
    lockType: lock_type ?? null,
    parameters: parameters ?? [],
    password: password ?? '',
    sensors: transformedSensors,
    thingId: thing_id ?? '',
    thingTypeUid: thing_type_uid ?? '',
    uuid: uuid ?? '',
  };
};

const payloadToGatewayDevicesList = (devices: AssetDevicePayload[]): AssetDeviceModel[] => {
  return devices.map((device) => payloadToGatewayDevice(device)) ?? [];
};

const payloadToAssetDeviceGateway = ({ devices, uuid }: AssetDeviceGatewayPayload): AssetDeviceGatewayModel => {
  const transformedDevices = payloadToGatewayDevicesList(devices);

  return {
    devices: transformedDevices,
    uuid: uuid ?? '',
  };
};

export const payloadToAssetDevicesResponse = ({ gateways }: AssetDevicesPayload): AssetDevicesModel => {
  const transformedGateways = gateways.map((gateway) => payloadToAssetDeviceGateway(gateway));

  return { gateways: transformedGateways };
};
