import { CustomGatewayDataModel } from 'components/pages/AssetPage/Tabs/TabGateway/resources/utils';
import { MaintenanceAction } from './reportMaintenance';

export interface GatewayModuleVersionUpdateStatePayload {
  uuid: string;
  state: string;
}

export enum GatewayUpdateProcessType {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  FAILED = 'FAILED',
}

export interface GatewayModuleVersionUpdateStateModel {
  uuid: string;
  state: GatewayUpdateProcessType;
}

export interface GatewayUpdateLatestModulesVersionsPayload {
  aass: string;
  system: string;
}

export interface GatewayUpdateLatestModulesVersionsModel {
  aass: string;
  system: string;
}

export enum GatewayModuleType {
  AASS = 'aass',
  SYSTEM = 'system',
}

export interface UpdateGatewayModuleVersionModel {
  gatewayId: string;
  module: GatewayModuleType;
}

export interface GatewayUpdateModel {
  gateway: CustomGatewayDataModel;
  module: GatewayModuleType;
}
export interface GatewayInitialUpdateModel {
  gateways: CustomGatewayDataModel[];
  gateway: CustomGatewayDataModel;
  module: GatewayModuleType;
}

export interface GatewayAssetPayload {
  id: string;
  alias: string | null;
  project: string | null;
}

export interface GatewaysListDataPayload {
  id: string;
  master_user: string | null;
  qr_code: string | null;
  hw_version: string | null;
  os_version: string | null;
  gateway_version: string | null;
  firmware_version: string | null;
  zwave_version: string | null;
  production_date: number | null;
  deployed_at: number | null;
  assistant_cloud: string | null;
  voice_assistant_user: string | null;
  daemon_version: string | null;
  helper_version: string | null;
  knx_version: string | null;
  sku: string | null;
  batch_id: string | null;
  batch_name: string | null;
  project_id: string | null;
  project_alias: string | null;
  type: string;
  dns_url: string | null;
  management_links: [];
  assets: GatewayAssetPayload[];
}

export interface GatewayListIncludedAssetPayload {
  id: string;
  alias: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  zip_code: string | null;
  street_type: string | null;
  street_name: string | null;
  block_number: string | null;
  apartment: string | null;
  latitude: string | null;
  longitude: string | null;
}

export interface GatewaysListIncludedPayload {
  assets: GatewayListIncludedAssetPayload[];
}

export interface GatewayListMetaPayload {
  all_results: number;
  page_size: number;
  results: number;
  total_pages: number;
}

export interface GatewayListLinksPayload {
  first: string;
  last: string;
  next: string;
  prev: string;
  self: string;
}

export interface GatewaysListResponsePayload {
  data: GatewaysListDataPayload[];
  meta: GatewayListMetaPayload;
  included: GatewaysListIncludedPayload;
  links: GatewayListLinksPayload;
}

export interface GatewayAssetModel {
  id: string;
  alias: string | null;
  project: string | null;
}

export interface GatewaysListDataModel {
  id: string;
  masterUser: string | null;
  qrCode: string | null;
  hwVersion: string | null;
  osVersion: string | null;
  gatewayVersion: string | null;
  firmwareVersion: string | null;
  zwaveVersion: string | null;
  productionDate: number | null;
  deployedAt: number | null;
  assistantCloud: string | null;
  voiceAssistantUser: string | null;
  daemonVersion: string | null;
  helperVersion: string | null;
  knxVersion: string | null;
  sku: string | null;
  batchId: string | null;
  batchName: string | null;
  projectId: string | null;
  projectAlias: string | null;
  type: string;
  dnsUrl: string | null;
  managementLinks: [];
  assets: GatewayAssetModel[];
}

export interface GatewayListIncludedAssetModel {
  id: string;
  alias: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  zipCode: string | null;
  streetType: string | null;
  streetName: string | null;
  blockNumber: string | null;
  apartment: string | null;
  latitude: string | null;
  longitude: string | null;
}

export interface GatewaysListIncludedModel {
  assets: GatewayListIncludedAssetModel[];
}

export interface GatewayListMetaModel {
  allResults: number;
  pageSize: number;
  results: number;
  totalPages: number;
}

export interface GatewayListLinksModel {
  first: string;
  last: string;
  next: string;
  prev: string;
  self: string;
}

export interface GatewaysListResponseModel {
  data: GatewaysListDataModel[];
  meta: GatewayListMetaModel;
  included: GatewaysListIncludedModel;
  links: GatewayListLinksModel;
}

export interface UpdateGatewayDataSharedSensorModel {
  sensorUuid: string;
  assets: string[];
}

export interface UpdateGatewayDataModel {
  alias?: string | null;
  qrGatewayId?: string | null;
  projectId?: string | null;
  sharedSensors?: UpdateGatewayDataSharedSensorModel[];
  vendor?: string;
  name?: string;
}

export interface UpdateGatewayDataSharedSensorsPayload {
  sensor_uuid: string;
  assets: string[];
}

export interface UpdateGatewayDataPayload {
  alias?: string | null;
  qr_gateway_id?: string | null;
  project_id?: string | null;
  shared_sensors?: UpdateGatewayDataSharedSensorsPayload[];
  vendor?: string;
  name?: string;
}

export enum UpdateGatewayDataPayloadType {
  ALIAS = 'alias',
  QR_GATEWAY_ID = 'qrGatewayId',
  PROJECT_ID = 'projectId',
  SHARED_SENSORS = 'sharedSensors',
  VENDOR = 'vendor',
  NAME = 'name',
}

export interface DeviceParameters {
  [key: string]: string;
}

export interface DeviceSensorPayload {
  sensor_id: string;
  sensor_uuid: string;
  item_name: string;
  sensor_type: string;
  item_type: string;
  room?: string;
  usage?: string;
  name?: string;
  device_id: string;
  access_sensor_id: string;
}

export interface DevicePayload {
  available_actions: MaintenanceAction[];
  battery_level: number | null;
  connectivity: string | null;
  device_id: string;
  device_type: string;
  device_name: string;
  enabled: boolean;
  lock_type?: string;
  parameters: DeviceParameters;
  password: string | null;
  sensors: DeviceSensorPayload[];
  thing_id: string;
  thing_type_uid?: string;
  uuid: string;
}

export interface GatewayDevicesResponsePayload {
  data: DevicePayload[];
}

export interface DeviceSensorModel {
  deviceId: string;
  accessSensorId: string;
  sensorId: string;
  sensorUuid: string;
  itemName: string;
  sensorType: string;
  itemType: string;
  room?: string;
  usage?: string;
  name?: string;
}

export enum DeviceLockType {
  PUBLIC = 'public',
  PRIVATE = 'private',
}

export interface DeviceModel {
  availableActions: MaintenanceAction[];
  deviceId: string;
  deviceType: string;
  deviceName: string;
  enabled: boolean;
  lockType?: DeviceLockType;
  parameters: DeviceParameters;
  password: string;
  sensors: DeviceSensorModel[];
  thingId: string;
  thingTypeUid?: string;
  batteryLevel: number | null;
  connectivity: string | null;
  uuid: string;
}
