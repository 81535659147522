import { TableColumnsType } from 'antd';
import { DeviceSensorModel } from 'core/domain/gateways/model';
import { AssetDeviceSensorModel } from 'core/domain/assets/models';
import './styles.scss';

export interface ExpandedPeripheralDevicesTranslations {
  nameText: string;
  roomText: string;
  usageText: string;
  typeText: string;
  notReportedText: string;
}

export const ExpandedPeripheralDevicesColumns = (translations: ExpandedPeripheralDevicesTranslations): TableColumnsType<DeviceSensorModel | AssetDeviceSensorModel> => {
  const { nameText, roomText, usageText, typeText, notReportedText } = translations;
  return [
    {
      title: 'Id',
      key: 'sensorId',
      dataIndex: 'sensorId',
    },
    {
      title: nameText,
      key: 'name',
      render: (text, { name }) => !!name ? <span>{name}</span> : <span className={'ExpandedPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: roomText,
      key: 'room',
      render: (text, { room }) => !!room ? <span>{room}</span> : <span className={'ExpandedPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: usageText,
      key: 'usage',
      render: (text, { usage }) => !!usage ? <span>{usage}</span> : <span className={'ExpandedPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
    {
      title: typeText,
      key: 'sensorType',
      render: (text, { sensorType }) => !!sensorType ? <span>{sensorType}</span> : <span className={'ExpandedPeripheralDevicesColumns__notReported'}>{notReportedText}</span>
    },
  ];
}
