import { useTranslation } from 'react-i18next';
import { ColumnSearchTranslationsType, DeviceDataIndex, getColumnSearchProps } from '../SearchPeripheralColumns';
import { DeviceModel } from 'core/domain/gateways/model';
import DeviceMenuExtra from 'components/organisms/DeviceMenuExtra';
import { Icon } from 'components/atoms/Icons';
import { COLORS } from 'constants/colors';
import { ColumnType, FilterConfirmProps, SortOrder } from 'antd/lib/table/interface';
import { AssetDeviceModel } from 'core/domain/assets/models';
import './styles.scss';

interface Props {
  gatewayId?: string;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  searchInput?: any;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
}

interface SortPeripheralsByBatteryLevelProps {
  a: number | null,
  b: number | null,
  sortOrder?: SortOrder,
}

export const sortPeripherals = (a: string, b: string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const sortPeripheralsByBatteryLevel = ({ a, b, sortOrder }: SortPeripheralsByBatteryLevelProps) => {
  if (a === null) return sortOrder === 'ascend' ? 1 : -1;
  if (b === null) return sortOrder === 'ascend' ? -1 : 1;
  return a - b;
};

export const ColumnsPeripheralDevices = ({
  gatewayId,
  columnSearchTranslations,
  searchInput,
  onSearch,
  onReset,
}: Props): ColumnType<DeviceModel | AssetDeviceModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: 'Id',
      key: 'deviceId',
      width: '10%',
      sorter: (a, b) => Number(a.deviceId) - Number(b.deviceId),
      sortDirections: ['ascend', 'descend'],
      dataIndex: 'deviceId',
    },
    {
      title: `${t('type')}`,
      key: 'device_type',
      width: '30%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      sorter: (a, b) => sortPeripherals(a.deviceType, b.deviceType),
      ...getColumnSearchProps({ dataIndex: 'deviceType', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: `${t('name')}`,
      key: 'device_name',
      width: '30%',
      filterIcon: (filtered: boolean) => (
        <Icon.Search style={{ color: filtered ? COLORS.corporate : undefined }} />
      ),
      ...getColumnSearchProps({ dataIndex: 'deviceName', onSearch, onReset, searchInput, translations: columnSearchTranslations }),
    },
    {
      title: `${t('remaining_battery')}`,
      key: 'batteryLevel',
      width: '20%',
      render: ({ batteryLevel }) => {
        return batteryLevel !== null
          ? <span>{`${batteryLevel}%`}</span>
          : <span className='Column__notReportedText'>{t('not_reported')}</span>
      },
      sorter: (a, b, sortOrder) => sortPeripheralsByBatteryLevel({ a: a.batteryLevel, b: b.batteryLevel, sortOrder }),
    },
    {
      title: t('actions'),
      key: 'menuExtra',
      width: '10%',
      render: (values) => {
        return gatewayId ? <DeviceMenuExtra data={values} gatewayId={gatewayId} /> : null;
      },
    },
  ];
};
