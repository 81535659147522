export enum AssetVerticalsEnum {
  BUILDING = 'BUILDING',
  HOME = 'HOME',
  RENT = 'RENT',
  OFFICE = 'OFFICE',
  PARKING_SLOT = 'PARKING_SLOT',
  NN_DISCOUNT_ALL_PARKINGS = 'NN_DISCOUNT_ALL_PARKINGS',
  BLOCKED = 'BLOCKED',
  HOTEL_ROOM = 'HOTEL_ROOM',
  HOTEL = 'HOTEL',
  VACATIONAL_RENTAL = 'VACATIONAL_RENTAL',
}

export enum AssetDeviceMaintenanceAction {
  BATTERY_REPLACEMENT = 'battery_replacement',
  REPLACEMENT = 'replacement',
  ADDED_DEVICE = 'added_device',
  REMOVED_DEVICE = 'removed_device',
  SOFTWARE_UPDATE = 'software_update',
  CLEANING = 'cleaning',
  ON_SITE_CHECK = 'on_site_check',
  REMOTE_CHECK = 'remote_check',
  CALL_ATTENDED = 'call_attended',
  MANAGEMENT_PASSWORD = 'management_password',
}

export interface AssetDeviceParameters {
  [key: string]: string;
}

export interface AssetDeviceSensorPayload {
  item_name: string;
  item_type: string;
  name: string | null;
  room: string | null;
  sensor_id: string;
  sensor_type: string;
  usage: string | null;
  device_id: string;
  access_sensor_id: string;
  sensor_uuid: string;
}

export interface AssetDevicePayload {
  available_actions: AssetDeviceMaintenanceAction[];
  connectivity: string | null;
  device_id: string;
  device_name: string;
  device_type: string;
  enabled: boolean;
  lock_type: string | null;
  parameters: AssetDeviceParameters;
  password: string | null;
  sensors: AssetDeviceSensorPayload[];
  thing_id: string;
  thing_type_uid: string | null;
  uuid: string;
  battery_level: number | null;
}

export interface AssetDeviceGatewayPayload {
  uuid: string;
  devices: AssetDevicePayload[];
}

export interface AssetDevicesPayload {
  gateways: AssetDeviceGatewayPayload[];
}

export interface AssetDevicesResponsePayload {
  data: AssetDevicesPayload;
}

export interface AssetDeviceSensorModel {
  itemName: string;
  itemType: string;
  name: string | null;
  room: string | null;
  sensorId: string;
  sensorType: string;
  usage: string | null;
  deviceId: string;
  accessSensorId: string;
  sensorUuid: string;
}

export interface AssetDeviceModel {
  availableActions: AssetDeviceMaintenanceAction[];
  connectivity: string | null;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  enabled: boolean;
  lockType: string | null;
  parameters: AssetDeviceParameters;
  password: string | null;
  sensors: AssetDeviceSensorModel[];
  thingId: string;
  thingTypeUid: string | null;
  uuid: string;
  batteryLevel: number | null;
}

export interface AssetDeviceGatewayModel {
  uuid: string;
  devices: AssetDeviceModel[];
}

export interface AssetDevicesModel {
  gateways: AssetDeviceGatewayModel[];
}

export interface AssetDevicesResponseModel {
  data: AssetDevicesModel;
}

export interface RegisterAssetModel {
  id: string;
  clientId: string;
  community: string;
  alias?: string | null;
  type: string;
  streetType: string;
  streetName: string;
  blockNumber: string;
  staircase?: string | null;
  flat?: string | null;
  door?: string | null;
  city: string;
  postalCode: string;
  province: string;
  country: string;
  description: string | null;
  gatewayId?: string | null;
  qrGatewayId?: string | null;
}

export interface RegisterAssetPayload {
  id: string;
  attributes: {
    community: string;
    alias?: string;
    client_id: string;
    type: string;
    description?: string | null;
    location: {
      country: string;
      province: string;
      city: string;
      postal_code: string;
      street_type: string;
      street_name: string;
      block_number: string;
      staircase?: string | null;
      flat?: string | null;
      door?: string | null;
    };
  };
  relationship?: {
    gateways: {
      data: {
        gateways_id?: string | null;
        qr_gateway_id?: string | null;
      };
    };
  };
}
