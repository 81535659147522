import { FC } from 'react';
import { DeviceModel, DeviceSensorModel } from 'core/domain/gateways/model';
import { TableColumnsType } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import TableAtom from 'components/atoms/TableAtom';
import { ColumnsPeripheralDevices } from './ColumnsPeripheralDevices';
import { ExpandedPeripheralDevicesColumns, ExpandedPeripheralDevicesTranslations } from './ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from './SearchPeripheralColumns';
import { AssetDeviceModel, AssetDeviceSensorModel } from 'core/domain/assets/models';
import './styles.scss'

interface PeripheralDevicesProps {
  title: string;
  expandedColumnsTranslations: ExpandedPeripheralDevicesTranslations;
  gatewayId?: string;
  devices?: DeviceModel[] | AssetDeviceModel[]
  loading?: boolean;
  searchInput: any;
  columnSearchTranslations?: ColumnSearchTranslationsType;
  onSearch?: (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
  onReset?: (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => void;
}

const PeripheralDevices: FC<PeripheralDevicesProps> = ({
  gatewayId,
  devices = [],
  loading = false,
  title,
  expandedColumnsTranslations,
  searchInput,
  columnSearchTranslations,
  onSearch,
  onReset,
}) => {

  const columns = ColumnsPeripheralDevices({ gatewayId, columnSearchTranslations, searchInput, onSearch, onReset });

  const expandedRowRender = ({ sensors }: DeviceModel | AssetDeviceModel) => {
    const expandedColumns: TableColumnsType<DeviceSensorModel | AssetDeviceSensorModel> = ExpandedPeripheralDevicesColumns(expandedColumnsTranslations);
    return (
      <TableAtom<DeviceSensorModel | AssetDeviceSensorModel>
        columns={expandedColumns}
        dataSource={sensors}
        pagination={false}
        rowKey={'sensorId'}
      />
    );
  };

  return (
    <>
      <h2>{title}</h2>
      <TableAtom<DeviceModel | AssetDeviceModel>
        className={'PeripheralDevices__expanded-table-wrapper'}
        loading={loading}
        columns={columns}
        expandable={{ expandedRowRender }}
        rowKey={'deviceId'}
        dataSource={devices}
        pagination={false}
      />
    </>
  );
};

export default PeripheralDevices;
